import { useState, useEffect } from 'react'
import {
  getBusinessCores,
  getBusinesses,
  getPartner,
  getAdvances,
  getOfferCollections,
} from 'api/fetches'
import {
  BusinessCore,
  BusinessDetails,
  CashAdvance,
  OfferCollection,
  Partner,
} from 'api/types'
import getMaxOfferCollection from 'helpers/utils/getMaxOfferCollection'

const useFetchAppState = (token: string) => {
  const [isFetching, setIsFetching] = useState(true)
  const [isError, setIsError] = useState(false)

  const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(-1)
  const [partner, setPartner] = useState<Partner | undefined>(undefined)
  const [businessCores, setBusinessCores] = useState<BusinessCore[]>([])
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails[]>([])
  const [advances, setAdvances] = useState<CashAdvance[]>([])
  const [offerCollection, setOfferCollection] =
    useState<OfferCollection | null>(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const [
          businessDetailsRes,
          partnerRes,
          businessCoresRes,
          advancesRes,
          offerCollectionsRes,
        ] = await Promise.all([
          getBusinesses(token),
          getPartner(token),
          getBusinessCores(token),
          getAdvances(token),
          getOfferCollections(token),
        ])

        const businessesWithAdvance = businessCoresRes.results.filter((c) =>
          advancesRes.results.find(
            (a) => a.business_id === c.id && a.state === 'outstanding'
          )
        )
        const maxOfferCollection = getMaxOfferCollection(
          offerCollectionsRes.results.filter(
            (c) => !businessesWithAdvance.find((b) => c.business_id === b.id)
          )
        )

        const sortedBusinessCores = businessCoresRes.results.sort((a, b) =>
          a.id > b.id ? 1 : -1
        )
        const businessIndex = sortedBusinessCores.findIndex(
          (c) => c.id === maxOfferCollection?.business_id
        )

        setSelectedBusinessIndex(Math.max(businessIndex, 0))

        setPartner(partnerRes.results[0])
        setBusinessCores(businessCoresRes.results)
        setBusinessDetails(businessDetailsRes.results)
        if (maxOfferCollection) {
          setOfferCollection(maxOfferCollection)
        }
        setAdvances(advancesRes.results)
        setIsFetching(false)
      } catch {
        setIsError(true)
      }
    }
    getData()
  }, [token])

  return {
    isFetching,
    isError,
    data: {
      partner,
      selectedBusinessIndex,
      businessCores,
      businessDetails,
      advances,
      offerCollection,
    },
  }
}

export default useFetchAppState
