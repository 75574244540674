export const hexToRGB = (hex: string) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    return {
      r: parseInt('#000', 16),
      g: parseInt('#000', 16),
      b: parseInt('#000', 16)
    }
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  }
}

export const hexToRGBA = (hex: string, alpha: number): string => {
  const color = hexToRGB(hex)
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`
}
