import { useEffect } from 'react'


type Size = { width: number; height: number }
export const useLayoutListener = (callback: ({ width, height }: Size) => void) => {
  const resizeObserverAvailable = 'ResizeObserver' in window
  if (resizeObserverAvailable) {
    const resizeObserver = new ResizeObserver(([window, ]) => {
      if(window.contentRect) {
        callback({ width: window.contentRect.width, height: window.contentRect.height })
      }
    })
    resizeObserver.observe(document.body)
  }

  useEffect(() => {
    // I think resize observer is pretty much always available so this probably would never happen
    if (!resizeObserverAvailable) {
      const postResize = () => callback({
        width: document.body.scrollWidth,
        height: document.body.scrollHeight,
      })
      const t = setTimeout(postResize, 1250)
      window.addEventListener('resize', postResize)

      return () => {
        clearTimeout(t)
        window.removeEventListener('resize', postResize)
      }
    }
  }, [resizeObserverAvailable, callback])

}
