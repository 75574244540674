import React, { useEffect } from 'react'

const Spinner = () => {
  useEffect(() => {
    const svg = document.getElementById('parafin-elements-spinner')
    const circle = document.getElementById('parafin-elements-circle')

    if (svg && circle) {
      svg.animate(
        {
          transform: ['rotate(0deg)', 'rotate(360deg)']
        },
        {
          duration: 2000,
          iterations: Infinity
        }
      )

      circle.animate(
        {
          strokeDasharray: ['1, 150', '90, 150', '90, 150'],
          strokeDashoffset: ['0', '-35', '-124'],
          stroke: ['#fff', '#fff']
        },
        {
          duration: 1500,
          iterations: Infinity
        }
      )
    }
  }, [])

  return (
    <svg
      id="parafin-elements-spinner"
      width="32"
      height="32"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      strokeLinecap="round"
    >
      <circle
        id="parafin-elements-circle"
        cx="25"
        cy="25"
        r="18"
        fill="none"
        strokeWidth="3"
        stroke="none"
      />
    </svg>
  )
}

export default Spinner
