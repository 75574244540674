import { useState } from 'react'
import Spinner from 'components/Spinner'
import { hexToRGBA } from 'helpers/utils/hexToRGBA'
import { IFrameElementsConfig } from 'adaptor/types'
import { useAnalytics } from 'helpers/hooks/useAnalytics'

type ButtonProps = {
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>
  config: IFrameElementsConfig
  postOpen: (route: string) => void
}
const Button = ({ config, postOpen, buttonRef }: ButtonProps) => {
  const { track } = useAnalytics()
  const [hovered, setHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <button
      ref={buttonRef}
      style={{
        position: 'relative',
        background: isLoading
          ? hexToRGBA(config.styles.variants?.button?.background ?? '#797979', 0.5)
          : hovered
            ? hexToRGBA(config.styles.variants?.button?.background ?? '#797979', 0.9)
            : config.styles.variants?.button?.background ?? '#797979',
        color: config.styles.variants?.button?.color ?? '#fff',
        fontSize: config.styles.variants?.button?.fontSize ?? '14px',
        borderRadius: config.styles.variants?.button?.borderRadius ?? '8px',
        minHeight: config.styles.variants?.button?.height ?? '40px',
        cursor: 'pointer',
        width: 'fit-content',
        padding: '0 24px',
        border: 'none',
        transition: '0.2s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textShadow: 'none',
        fontFamily: 'Averta, sans-serif', // TODO: support fonts
        fontWeight: 600,
      }}
      onClick={() => {
        // No need to ever set this back to false - we can work with the guarantee that
        // widget will always be remounted when closing the dashboard
        track('Clicked View my offer')
        setIsLoading(true)
        postOpen('offer')
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={isLoading}
    >
      {isLoading &&
        <div
          style={{
            position: 'absolute',
            top: '0px', right: '0px', bottom: '0px', left: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      }
      <span style={{visibility: isLoading ? 'hidden' : 'visible'}}>
        View my offer
      </span>
    </button>
  )
}

export default Button
