import { useRef } from 'react'
import { usePartnerAdaptor } from 'adaptor/usePartnerAdaptor'
import Button from 'components/Button'
import InitSession from 'services/InitSession'
import AppStateProvider from 'providers/AppState'
import useFetchAppState from 'helpers/hooks/useFetchAppState'
import Spinner from 'components/Spinner'
import ImpressionWrapper from 'components/ImpressionWrapper'

const App = () => {
  const { token, config, postOpen } = usePartnerAdaptor()
  const {
    isFetching,
    isError,
    data: {
      selectedBusinessIndex,
      partner,
      businessCores,
      businessDetails,
      offerCollection,
      advances,
    },
  } = useFetchAppState(token ?? '')

  const buttonRef = useRef(null)

  if (isFetching) return <Spinner />
  if (isError || !partner) return <div>Error fetching data!</div>

  return (
    <AppStateProvider
      selectedBusinessIndex={selectedBusinessIndex}
      partner={partner}
      businessCores={businessCores}
      businessDetails={businessDetails}
      advances={advances}
      offerCollection={offerCollection}
    >
      <InitSession />
      <ImpressionWrapper buttonRef={buttonRef}>
        <Button
          config={config}
          postOpen={() => postOpen('', { selectedBusinessIndex })}
          buttonRef={buttonRef}
        />
      </ImpressionWrapper>
    </AppStateProvider>
  )
}

export default App
