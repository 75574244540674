import { useEffect, useState } from 'react'
import { useAppState } from 'providers/AppState'
import { useAnalytics } from 'helpers/hooks/useAnalytics'

const InitSession = () => {
  const { selectedBusinessIndex, businessCores, businessDetails, partner } =
    useAppState()
  const { identify } = useAnalytics()
  const [identified, setIdentified] = useState(false)

  useEffect(() => {
    if (!identified && businessCores && businessDetails && partner) {
      identify()
      setIdentified(true)
    }
  }, [
    businessDetails,
    partner,
    businessCores,
    identify,
    identified,
    selectedBusinessIndex,
  ])

  return <></>
}

export default InitSession
