import { createContext, useContext } from 'react'
import {
  BusinessCore,
  BusinessDetails,
  Partner,
  CashAdvance,
  OfferCollection,
} from 'api/types'

type AppStateContextType = {
  selectedBusinessIndex: number
  partner: Partner
  businessCores: BusinessCore[]
  businessDetails: BusinessDetails[]
  advances: CashAdvance[]
  offerCollection: OfferCollection | null
}

const AppStateContext = createContext<AppStateContextType | undefined>(undefined)

type Props = AppStateContextType & {
  children: React.ReactNode
}

const AppStateProvider = ({ children, ...props }: Props) => {
  return (
    <AppStateContext.Provider value={props}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  const context = useContext(AppStateContext)
  if (context === undefined)
    throw new Error('AppState must be used within AppStateProvider')
  return context
}

export default AppStateProvider
