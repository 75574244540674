import axios from 'axios'
import { ENVIRONMENT } from 'config'
import {
  Paginated,
  OfferCollection,
  CashAdvance,
  BusinessCore,
} from 'api/types'


const baseEndpoint = `https://api${ENVIRONMENT === 'production' ? '' : '.dev'}.parafin.com`


export const getBusinesses = async (token: string) => {
  const res = await axios.get(`${baseEndpoint}/businesses`, {
    headers: { authorization: `Bearer ${token}` },
  })

  return res.data
}

export const getPartner = async (token: string) => {
  const res = await axios.get(`${baseEndpoint}/partners`, {
    headers: { authorization: `Bearer ${token}` },
  })

  return res.data
}

export const getBusinessCores = async (token: string) => {
  const res = await axios.get(`${baseEndpoint}/businesses/core`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return res.data as Paginated<BusinessCore>
}

export const getAdvances = async (token: string) => {
  const res = await axios.get(`${baseEndpoint}/cash_advances`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return res.data as Paginated<CashAdvance>
}

export const getOfferCollections = async (token: string) => {
  const res = await axios.get(`${baseEndpoint}/cash_advance_offer_collections`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return res.data as Paginated<OfferCollection>
}
