import { Font, ColorSet, LayoutStyles, IFrameElementsConfig } from './types'

const defaultMainFont: Font = {
  family: 'auto',
  size: '16px',
  weight: 400,
  lineHeight: '24px',
  letterSpacing: 'normal',
}

const defaultElementsColors: ColorSet = {
  primary: '#191919',
  secondary: '#494949',
  tertiary: '#e7e7e7',
  accent: '#797979',
  border: '#e7e7e7',
}

const defaultElementsLayout: LayoutStyles = {
  borderRadius: '4px'
}

export const buildConfig = (encodedConfig: string | null): IFrameElementsConfig => {
  // TODO: Log received configs
  const config = encodedConfig ? JSON.parse(atob(encodedConfig)) : { }

  return {
    styles: {
      fonts: {
        main: {
          ...defaultMainFont,
          ...config?.styles?.fonts?.main,
        }
      },
      colors: {
        ...defaultElementsColors,
        ...config?.styles?.colors,
      },
      layout: {
        ...defaultElementsLayout,
        ...config?.styles?.layout,
      },
      variants: config?.variants,
    },
  }
}
