import { useCallback, useMemo } from 'react'
import { useAppState } from 'providers/AppState'
import { APP_VERSION, ENVIRONMENT } from 'config'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENT_WRITE_KEY } from 'config'

export const useAnalytics = () => {
  const {
    partner,
    selectedBusinessIndex,
    businessCores,
    businessDetails,
    advances,
    offerCollection,
  } = useAppState()

  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY }),
    []
  )

  const standardPayload = useMemo(() => {
    const core = businessCores[selectedBusinessIndex]
    const details = businessDetails[selectedBusinessIndex]
    const advance = advances[selectedBusinessIndex]
    const maxApprovedAmount = offerCollection?.offers.reduce(
      (maxOffer, { chunks }) => {
        const chunkMax = chunks.reduce(
          (max, { amount_range }) => Math.max(+amount_range[1], max),
          0
        )
        return Math.max(chunkMax, maxOffer)
      },
      0
    )
    const approvalAmount =
      maxApprovedAmount && maxApprovedAmount > 0 ? maxApprovedAmount : null
    return {
      screen: {
        dimensions: `${window.screen.width}x${window.screen.height}/${window.innerWidth}x${window.innerHeight}`,
        touch: navigator.maxTouchPoints > 0,
      },
      eventSource: 'elements',
      appVersion: APP_VERSION,
      environment: ENVIRONMENT,
      businessId: core?.id,
      acceptedAmount: advance?.amount,
      approvalAmount,
      state: !advance?.amount && approvalAmount ? 'offer' : null,
      estimatedPayoffDate: advance?.estimated_repayment_date,
      externalId: core?.external_id,
      legalBusinessName: details?.legal_business_name,
      name: details?.name,
      outstandingAmount:
        advance?.state === 'outstanding' ? advance?.amount : null,
      partnerId: partner.id,
      partnerName: partner.name,
      partnerSlug: partner.slug,
      totalAdvances: advances.length,
    }
  }, [
    partner,
    businessCores,
    businessDetails,
    selectedBusinessIndex,
    advances,
    offerCollection?.offers,
  ])

  const track = useCallback(
    (event: string, type?: string) => {
      analytics.track(event, {
        eventType: type ?? 'interaction',
        ...standardPayload,
      })
    },
    [analytics, standardPayload]
  )

  const identify = useCallback(() => {
    analytics.identify(standardPayload.businessId, {
      eventType: 'identify',
      ...standardPayload,
    })
  }, [analytics, standardPayload])

  return {
    track,
    identify,
  }
}
