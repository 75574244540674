import { useEffect, useMemo, ReactNode } from 'react'
import { useAnalytics } from 'helpers/hooks/useAnalytics'

type Props = {
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>
  children?: ReactNode
}

const ImpressionWrapper = ({ buttonRef, children }: Props) => {
  const { track } = useAnalytics()

  const viewportObserver = useMemo(
    () =>
      'IntersectionObserver' in window
        ? new IntersectionObserver(
            ([entry]) => {
              entry.isIntersecting
                ? track('Element is visible', 'impression')
                : track('Element is not visible', 'impression')
            },
            { threshold: 1 }
          )
        : null,
    [track]
  )

  useEffect(() => {
    viewportObserver &&
      buttonRef.current &&
      viewportObserver.observe(buttonRef.current)
    return () => {
      viewportObserver?.disconnect()
    }
  }, [viewportObserver, buttonRef])

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>{children}</div>
  )
}

export default ImpressionWrapper
