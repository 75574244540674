import { useCallback, useEffect, useMemo } from 'react'
import { AdaptedValues, OptInResponse } from './types'
import { buildConfig } from './helpers'
import { useLayoutListener } from '../helpers/hooks/useLayoutListener'


export const usePartnerAdaptor = (): AdaptedValues => {
  // absolute first thing that should happen is to tell the parent we're alive
  // we're alive means we can handle our own errors from here on - parent should
  // not try to do any error handling or loading after this point.
  useEffect(() => window.parent.postMessage({ message: 'ping' }, '*'), [])

  const params = useMemo(() => new URLSearchParams(window.location.search), [])
  const token = useMemo(() => params.get('token') ?? null, [params])
  const host = useMemo(() => params.get('host') ?? null, [params])
  const config = useMemo(() => buildConfig(params.get('config')), [params])
  const state = useMemo(() => params.get('state') ?? null, [params])

  const postOpen = useCallback((route: string | undefined, state?: any) => {
    window.parent.postMessage({
      message: 'expand',
      route,
      state,
    }, host ?? '')
  }, [host])


  const postOptIn = useCallback((externalId: string): Promise<any> => {
    window.parent.postMessage({
      message: 'opt-in',
      externalId,
    }, host ?? '')

    return new Promise((res, rej) => {
      const listener = ({ origin, data }: OptInResponse) => {
        if (origin !== host) {
          // TODO: Log x-origin issue
          return
        }
        if (data.message !== 'opt-in') {
          // TODO: Log unrecognized message
          return
        }

        window.removeEventListener('message', listener)
        res(data.business)
      }

      window.addEventListener('message', listener, false)
      setTimeout(() => {
        rej(new Error('Request timed out'))
        window.removeEventListener('message', listener)
      }, 5000)
    })
  }, [host])


  useLayoutListener(({ height }) => {
    window.parent.postMessage({
      message: 'request-height',
      height: `${height}px`,
    }, host ?? '')
  })

  return { token, config, state, postOpen, postOptIn }
}
