import { OfferCollection } from 'api/types'


const getMaxOfferCollection = (collections: OfferCollection[]) => {
  if (collections.length === 0) return null

  const maxOffers = collections.map(c =>
    Math.max.apply(
      null,
      c.offers.map(o =>
        Math.max.apply(null, o.chunks.map(ch => Number(ch.amount_range[1])))
      ),
    )
  )
  const maxIndex = maxOffers.findIndex(o => o === Math.max.apply(null, maxOffers))

  return collections[maxIndex]
}

export default getMaxOfferCollection
